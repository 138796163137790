<template>
    <section class="services">
        <div class="container">
            <div class="services-title">
                <h2 class="title-1">Why choose us?</h2>
            </div>
            <div class="services-row">
                <div class="service-card">
                    <img class="service-card-img" src="../assets/img/icons/wallet.svg" alt="Honest prices">
                    <h3 class="service-card-title">Honest prices</h3>
                    <p>We have the best prices for tours, and a huge selection of destinations will not leave indifferent even avid tourist.</p>
                </div>

                <div class="service-card">
                    <img class="service-card-img" src="../assets/img/icons/support.svg" alt="Full support">
                    <h3 class="service-card-title">Full support</h3>
                    <p>Contacting us, you get full support, from choosing the tour and ending with advice on what to take with you on a trip.</p>
                </div>

                <div class="service-card">
                    <img class="service-card-img" src="../assets/img/icons/settings.svg" alt="Document processing">
                    <h3 class="service-card-title">Document processing</h3>
                    <p>With us you won't have to worry about processing the necessary documents.</p>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
export default {
    name: "ServicesMain"
}
</script>
  
<style scoped>

.services {
    padding: 50px 0 100px 0;
    background-color: var(--color-bg-soft);
}

.services-title {
    margin-bottom: 50px;
    text-align: center;
}

.services-row {
    display: flex;
    column-gap: 30px;
    row-gap: 58px;
    flex-wrap: wrap;
}

.service-card {
    flex: 1 1 0;
    min-width: 315px;
    cursor: default;
}

.service-card-img {
    width: 125px;
    margin-bottom: 30px;
}

.service-card-title {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 25px;
    text-transform: uppercase;
    color: var(--color-text-3);
}

.service-card p {
    font-size: 15px;
    line-height: 2;
}

/* Media queries */

@media (max-width: 875px) {
    .services {
        padding: 50px 0 75px 0;
    }
}

@media (max-width: 580px) {
    .services {
        padding: 40px 0 50px 0;
    }

    .services-title {
        margin-bottom: 40px;
    }

    .services-row {
        row-gap: 50px;
    }

    .service-card {
        min-width: 100%;
    }

    .service-card-title {
        font-size: 23px;
    }

    .service-card-img {
        margin-bottom: 18px;
    }
}

@media (max-width: 374px) {
    .services {
        padding: 35px 0 50px 0;
    }

    .services-title {
        margin-bottom: 35px;
    }

    .service-card-title {
        font-size: 21px;
    }
}

</style>