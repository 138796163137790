<template>
    <section class="preview">
        <div class="container">
            <div class="preview-row">
                <div class="preview-content">
                    <h1>Night Cities</h1>
                    <p>Travel to cities with the best nightlife in the world!</p>
                </div>
                <div class="preview-img">
                    <img src="../assets/img/icons/happy-big.svg" alt="Happy Image">
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
export default {
    name: "PreviewMain",
    methods: {
        parallax() {
            const preview = document.querySelector(".preview");
            const previewImg = document.querySelector(".preview-img");

            preview.onmousemove = (event) => {
                if (window.innerWidth > 1228) {
                    let x = event.clientX / window.innerWidth;
                    let y = event.clientY / window.innerHeight;
                    previewImg.style.transform = `translate(-${x * 25}px, -${y * 10}px)`;
                }
            };
        }
    },
    mounted() {
        this.parallax();
    }
}
</script>
  
<style scoped>

.preview {
    background-color: var(--color-bg);
}

.preview-row {
    padding: 100px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 25px;
}

.preview-content {
    max-width: 550px;
    cursor: default;
}

.preview-content h1 {
    margin-bottom: 10px;
    font-size: 45px;
    font-weight: 700;
    background: var(--background-gradient);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.preview-content p {
    font-size: 35px;
    font-weight: 600;
}

.preview-img {
    width: 500px;
    transition: transform 0.1s;
}

.preview-img img {
    width: 100%;
}

/* Media queries */

@media (max-width: 875px) {
    .preview-row {
        padding: 75px 0;
        flex-direction: column;
        row-gap: 25px;
    }

    .preview-content {
        max-width: 100%;
        text-align: center;
    }

    .preview-content h1 {
        font-size: 55px;
    }

    .preview-img {
        width: 100%;
        max-width: 400px;
    }
}

@media (max-width: 580px) {
    .preview-row {
        padding: 50px 0;
        row-gap: 25px;
    }

    .preview-content h1 {
        font-size: 40px;
    }

    .preview-content p {
        font-size: 25px;
        font-weight: 500;
    }

    .preview-img {
        max-width: 300px;
    }
}

@media (max-width: 374px) {
    .preview-row {
        row-gap: 20px;
    }

    .preview-content h1 {
        font-size: 35px;
    }

    .preview-content p {
        font-size: 21px;
        font-weight: 400;
    }
}

</style>
