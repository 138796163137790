<template>
    <div class="preloader">
        <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "PreloaderMain",
    methods: {
        hidePreloader() {
            window.onload = () => {
                setTimeout(() => {
                    const preloader = document.querySelector(".preloader");
                    preloader.classList.add("hide-preloader");
                    setTimeout(() => {
                        preloader.classList.add("preloader-hidden");
                        document.body.classList.remove("overlay");
                    }, 900);
                }, 500);
            };
        }
    },
    mounted() {
        this.hidePreloader();
    }
}
</script>
  
<style scoped>

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: var(--color-bg);

    display: flex;
    justify-content: center;
    align-items: center;
}

.hide-preloader {
    animation: hidePreloader 1s;
}

@keyframes hidePreloader {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.preloader-hidden {
    display: none;
}

.spinner {
    width: 55px;
    height: 55px;

    position: relative;
    margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 50% { 
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

</style>
