<template>
    <PreloaderMain/>
    <HeaderMain/>
    <section class="gallery">
        <div class="container">
            <div class="title-gallery">
                <h2 class="city-title">Miami</h2>
                <img v-on:click="playSound" :class="{ paused: isActivePause }" class="sound-button" src="../assets/img/icons/sound.gif" alt="Sound" :title="isActivePause ? 'Play Sound' : 'Pause'">
            </div>
            <div class="grid-gallery">
                <div class="grid-item" @click="openLightboxOnSlide(1)">
                    <img src="../assets/img/cities/miami/1.jpg" alt="Image">
                </div>
                <div class="grid-item" @click="openLightboxOnSlide(2)">
                    <img src="../assets/img/cities/miami/2.jpg" alt="Image">
                </div>
                <div class="grid-item" @click="openLightboxOnSlide(3)">
                    <img src="../assets/img/cities/miami/3.jpg" alt="Image">
                </div>
                <div class="grid-item" @click="openLightboxOnSlide(4)">
                    <img src="../assets/img/cities/miami/4.jpg" alt="Image">
                </div>
                <div class="grid-item" @click="openLightboxOnSlide(5)">
                    <img src="../assets/img/cities/miami/5.jpg" alt="Image">
                </div>
                <div class="grid-item" @click="openLightboxOnSlide(6)">
                    <img src="../assets/img/cities/miami/6.jpg" alt="Image">
                </div>
                <div class="grid-item" @click="openLightboxOnSlide(7)">
                    <img src="../assets/img/cities/miami/7.jpg" alt="Image">
                </div>
                <div class="grid-item" @click="openLightboxOnSlide(8)">
                    <img src="../assets/img/cities/miami/8.jpg" alt="Image">
                </div>
                <div class="grid-item" @click="openLightboxOnSlide(9)">
                    <img src="../assets/img/cities/miami/9.jpg" alt="Image">
                </div>
                <div class="grid-item" @click="openLightboxOnSlide(10)">
                    <img src="../assets/img/cities/miami/10.jpg" alt="Image">
                </div>
                <div class="grid-item" @click="openLightboxOnSlide(11)">
                    <img src="../assets/img/cities/miami/11.jpg" alt="Image">
                </div>
                <div class="grid-item" @click="openLightboxOnSlide(12)">
                    <img src="../assets/img/cities/miami/12.jpg" alt="Image">
                </div>
            </div>
        </div>
    </section>
    <FsLightbox
		:toggler="toggler"
		:slide="slide"
		:sources="[
                require('../assets/img/cities/miami/1.jpg'),
				require('../assets/img/cities/miami/2.jpg'),
                require('../assets/img/cities/miami/3.jpg'),
                require('../assets/img/cities/miami/4.jpg'),
                require('../assets/img/cities/miami/5.jpg'),
                require('../assets/img/cities/miami/6.jpg'),
                require('../assets/img/cities/miami/7.jpg'),
                require('../assets/img/cities/miami/8.jpg'),
                require('../assets/img/cities/miami/9.jpg'),
                require('../assets/img/cities/miami/10.jpg'),
                require('../assets/img/cities/miami/11.jpg'),
                require('../assets/img/cities/miami/12.jpg')
		]"
        :exitFullscreenOnClose="true"
	/>
    <FooterMain/>
</template>

<script>
import PreloaderMain from "../components/Preloader.vue";
import FsLightbox from "fslightbox-vue/v3";
import HeaderMain from "../components/Header.vue";
import FooterMain from "../components/Footer.vue";

const sound = new Audio(require("../assets/audio/miami-theme.mp3"));
sound.loop = true;

export default {
    name: "MiamiPage",
    components: {
        PreloaderMain, FsLightbox, HeaderMain, FooterMain
    },
    data() {
        return {
            toggler: false,
            slide: 1,
            isActivePause: true
        }
    },
    methods: {
        openLightboxOnSlide(number) {
            this.slide = number;
			this.toggler = !this.toggler;
        },
        playSound() {
            this.isActivePause = !this.isActivePause;
            sound.paused ? sound.play() : sound.pause();
        },
        checkWindow() {
            window.onfocus = () => this.isActivePause ? sound.pause() : sound.play();
            window.onblur = () => sound.pause();
        }
    },
    mounted() {
        document.title = "Miami | Night Cities";
        this.checkWindow();
    }
}
</script>

<style scoped>

.gallery {
    padding: 50px 0 75px 0;
    background-color: var(--color-bg);
}

.title-gallery {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 15px;
    row-gap: 15px;
}

.city-title {
    font-size: 45px;
    font-weight: 700;
    cursor: default;
    background: var(--background-gradient);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.sound-button {
    width: 58px;
	transition: all .25s ease;
	cursor: pointer;
}

.sound-button.paused {
	opacity: 0.5;
}

.grid-gallery {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 250px;
    grid-auto-flow: dense;
    grid-gap: 25px;
}

.grid-item {
    position: relative;
    border-radius: 15px;
    background-color: var(--color-bg);
    overflow: hidden;
    transition: all .3s;
    cursor: pointer;
}

@media (hover: hover) {
    .grid-item:hover {
        transform: scale(1.05);
        box-shadow: 7px 7px 15px 0px rgba(215, 17, 76, 0.144);
    }
}

.grid-item:nth-child(3n - 2) {
    grid-column: span 2;
    grid-row: span 2;
}

.grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Media queries */

@media (max-width: 1228px) {
    .grid-item:hover {
        transform: none;
        box-shadow: none;
    }
}

@media (max-width: 580px) {
    .gallery {
        padding: 40px 0 58px 0;
    }

    .title-gallery {
        margin-bottom: 40px;
        flex-direction: row;
        justify-content: space-between;
    }

    .city-title {
        font-size: 35px;
    }

    .sound-button {
        width: 50px;
    }

    .grid-gallery {
        grid-gap: 15px;
    }

    .grid-item:nth-child(3n - 2) {
        grid-column: unset;
        grid-row: unset;
    }
}

@media (max-width: 374px) {
    .gallery {
        padding: 35px 0 55px 0;
    }

    .title-gallery {
        margin-bottom: 35px;
        flex-direction: column;
        align-items: flex-start;
    }

    .city-title {
        font-size: 30px;
    }

    .sound-button {
        width: 45px;
    }
}

</style>
