<template>
    <section class="cities">
        <div class="container">
            <div class="cities-title">
                <h2 class="title-1">Cities</h2>
            </div>
            <div class="cities-box">
                <div v-for="el in weatherInfo" :key="el.id" class="city">
                    <div class="city-name">
                        <h3>{{ el.city }}</h3>
                    </div>
                    <div class="city-weather">
                        <div class="weather-basic">
                            <img :src="require('../assets/img/weather/' + el.img)" alt="Weather">
                            <p class="temperature">{{ el.temperature }} °C</p>
                            <p class="description">{{ el.description }}</p>
                        </div>
                        <div class="weather-other">
                            <div class="humidity">
                                <img src="../assets/img/icons/humidity.svg" alt="Humidity">
                                <div class="humidity-content">
                                    <span>{{ el.humidity }} %</span>
                                    <p>Humidity</p>
                                </div>
                            </div>
                            <div class="wind">
                                <img src="../assets/img/icons/wind.svg" alt="Wind">
                                <div class="wind-content">
                                    <span>{{ el.wind }} M/s</span>
                                    <p>Wind Speed</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="city-gallery">
                        <a class="btn" :href="el.route">View gallery</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import { API_KEY, BASE_URL, CITIES } from "../config/config";
import { capitalizeFirstLetter } from "../utils/utils";

export default {
    name: "CitiesMain",
    data() {
        return {
            weatherInfo: []
        }
    },
    methods: {
        async getWeather() {
            // forEach не чекатиме виконання завершення ітерації.
            // forEach - синхронна операція.
            // Вона просто запустить завдання і йтиме далі.
            // for..of допоможе дочекатися результату виконання тіла циклу.
            for (let el of CITIES) {
                let response = await fetch(`${BASE_URL}?q=${el.city}&units=metric&appid=${API_KEY}`);
                let result = await response.json();
                let item = {
                    id: el.id,
                    city: el.city,
                    route: el.route,
                    temperature: Math.round(result.main.temp),
                    humidity: Math.round(result.main.humidity),
                    wind: Math.round(result.wind.speed),
                    description: capitalizeFirstLetter(result.weather[0].description),
                    img: result.weather[0].main + ".svg"
                };
                this.weatherInfo.push(item);
            }
        }
    },
    mounted() {
        this.getWeather();
    }
}
</script>
  
<style scoped>

.cities {
    padding: 50px 0 100px 0;
    background-color: var(--color-bg);
}

.cities-title {
    margin-bottom: 50px;
    text-align: center;
}

.cities-box {
    display: grid;
    grid-template-columns: repeat(auto-fill, 31.3%);
    width: 100%;
    justify-content: center;
    justify-items: center;
    column-gap: 3%;
    row-gap: 35px;
}

.city {
    width: 100%;
    max-height: max-content;
    padding: 30px;

    display: flex;
    flex-direction: column;
    row-gap: 30px;

    border-radius: 18px;
    border: 1px solid #fb6b74;
    background-color: var(--color-bg-soft);
    cursor: default;
}

.city-name h3 {
    text-align: center;
    font-size: 34px;
    font-weight: 600;
    color: var(--color-text-3);
}

.city-weather {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

.weather-basic {
    text-align: center;
}

.weather-basic img {
    width: 100%;
    max-width: 150px;
    margin-bottom: 18px;
}

.temperature {
    margin-bottom: 5px;
    font-size: 28px;
}

.description {
    font-size: 21px;
}

.weather-other {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
}

.humidity {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
}

.wind {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 15px;
}

.humidity img, .wind img {
    width: 40px;
}

.humidity-content span, .wind-content span {
    font-size: 18px;
}

.humidity-content p, .wind-content p {
    font-size: 14px;
}

.city-gallery {
    text-align: center;
}

/* Media queries */

@media (max-width: 1150px) {
    .cities-box {
        grid-template-columns: repeat(auto-fill, 48.5%);
    }

    .weather-other {
        justify-content: space-around;
    }
}

@media (max-width: 1000px) {
    .cities-box {
        row-gap: 30px;
    }
}

@media (max-width: 875px) {
    .cities {
        padding: 50px 0 75px 0;
    }
}

@media (max-width: 800px) {
    .cities-box {
        grid-template-columns: repeat(auto-fill, 75%);
        row-gap: 35px;
    }
}

@media (max-width: 580px) {
    .cities {
        padding: 40px 0 50px 0;
    }

    .cities-title {
        margin-bottom: 40px;
    }

    .cities-box {
        grid-template-columns: repeat(auto-fill, 100%);
    }
}

@media (max-width: 374px) {
    .cities {
        padding: 35px 0 50px 0;
    }

    .cities-title {
        margin-bottom: 35px;
    }

    .cities-box {
        row-gap: 30px;
    }

    .city {
        padding: 30px 15px;
    }

    .city-name h3 {
        font-size: 28px;
    }

    .weather-basic img {
        max-width: 125px;
    }

    .weather-other {
        justify-content: space-between;
    }

    .description {
        font-size: 18px;
    }
}

</style>