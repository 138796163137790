<template>
    <PreloaderMain/>
    <HeaderMain/>
    <PreviewMain/>
    <ServicesMain/>
    <CitiesMain/>
    <FooterMain/>
</template>

<script>
import PreloaderMain from "../components/Preloader.vue";
import HeaderMain from "../components/Header.vue";
import PreviewMain from "../components/Preview.vue";
import ServicesMain from "../components/Services.vue";
import CitiesMain from "../components/Cities.vue";
import FooterMain from "../components/Footer.vue";

export default {
    name: "MainPage",
    components: {
        PreloaderMain, HeaderMain, PreviewMain, ServicesMain, CitiesMain, FooterMain
    }
}
</script>

<style></style>