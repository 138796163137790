<template>
    <footer class="footer">
        <div class="container">
            <div class="footer-title">
                <h2 class="title-1">Contacts</h2>
            </div>
            <div class="footer-content">
                <p>Want to know more or just chat?<br>You are welcome!</p>
            </div>
            <div class="footer-button">
                <a href="#!" class="btn">Send message</a>
            </div>
            <div class="footer-social">
                <a href="#!"><img src="../assets/img/icons/telegram.svg" alt="Telegram"></a>
                <a href="#!"><img src="../assets/img/icons/instagram.svg" alt="Instargam"></a>
                <a href="#!"><img src="../assets/img/icons/twitter.svg" alt="Twitter"></a>
            </div>
            <div class="footer-like">
                <p>Like me on<br>Telegram, Instargam, Twitter</p>
            </div>
        </div>
    </footer>
</template>
  
<script>
export default {
    name: "FooterMain"
}
</script>
  
<style scoped>

.footer {
    padding: 50px 0 70px 0;
    border-top: var(--border-line);
    background-color: var(--color-bg);
    text-align: center;
}

.footer-title {
    margin-bottom: 40px;
}

.footer-content {
    margin-bottom: 30px;
    font-size: 23px;
    font-weight: 400;
    line-height: 1.8;
    cursor: default;
}

.footer-content p + p {
    margin-top: 25px;
}

.footer-button {
    margin-bottom: 40px;
}

.footer-social {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 75px;
    margin-bottom: 33px;
}

.footer-social img {
    width: 40px;
    opacity: 0.7;
    transition: all .4s;
}

@media (hover: hover) {
    .footer-social a:hover img {
        opacity: 1;
        transform: translateY(-5px);
    }
}

@media (hover: none) {
    .footer-social a:active img {
        opacity: 1;
        transform: translateY(-5px);
    }
}

.footer-like {
    font-size: 14px;
    font-weight: 400;
    color: #828282;
    cursor: default;
}

/* Media queries */

@media (max-width: 580px) {
    .footer {
        padding: 40px 0 50px 0;
    }

    .footer-title {
        margin-bottom: 30px;
    }

    .footer-content {
        font-size: 18px;
    }

    .footer-social {
        column-gap: 55px;
    }
}

@media (max-width: 374px) {
    .footer {
        padding: 35px 0 50px 0;
    }

    .footer-title {
        margin-bottom: 25px;
    }

    .footer-content {
        font-size: 15px;
    }

    .footer-social {
        column-gap: 35px;
    }

    .footer-social img {
        width: 35px;
    }
}

</style>
