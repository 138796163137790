<template>
    <ModalMain v-if="isModalVisible" @close="closeModal"/>
    <header class="header">
        <div class="container">
            <div class="header-nav">
                <a href="/" class="logo">Night Cities</a>
                <nav id="nav" class="nav">
                    <ul class="nav-list">
                        <li class="nav-item"><a class="nav-link" @click="openModal">Cities</a></li>
                        <li class="nav-item"><a class="nav-link" @click="openModal">News</a></li>
                        <li class="nav-item"><a class="nav-link" @click="openModal">Services</a></li>
                        <li class="nav-item"><a class="nav-link" @click="openModal">About Us</a></li>
                        <li class="nav-item"><a class="nav-link" @click="openModal">Contacts</a></li>
                        <li class="nav-socials">
                            <a href="#!"><img src="../assets/img/icons/telegram.svg" alt="Telegram"></a>
                            <a href="#!"><img src="../assets/img/icons/instagram.svg" alt="Instagram"></a>
                            <a href="#!"><img src="../assets/img/icons/twitter.svg" alt="Twitter"></a>
                        </li>
                    </ul>
                    <button id="nav-btn" class="nav-button" v-on:click="openNavigation">
                        <img id="nav-btn-img" src="../assets/img/icons/nav-open.svg" alt="Nav button">
                    </button>
                </nav>
            </div>
        </div>
    </header>
</template>
  
<script>
import ModalMain from "../components/Modal.vue";

export default {
    name: "HeaderMain",
    components: {
        ModalMain
    },
    data() {
        return {
            openNavMobile: false,
            isModalVisible: false
        }
    },
    methods: {
        checkScroll() {
            const mediaQuery = window.matchMedia("(min-width: 1000px)");
            const navList = document.querySelector(".nav-list");
            mediaQuery.addEventListener("change", (event) => {
                if (event.matches == true) {
                    document.body.classList.remove("overlay");
                    // Modal components
                    this.isModalVisible = false;
                    // Modal components
                } else if (event.matches == false && navList.classList.contains("open")) {
                    document.body.classList.add("overlay");
                }
            });
        },
        openNavigation() {
            const navList = document.querySelector(".nav-list");
            const navBtnImg = document.querySelector("#nav-btn-img");

            if (this.openNavMobile == false) {
                navList.classList.add("open");
                document.body.classList.add("overlay");
                navBtnImg.src = require("../assets/img/icons/nav-close.svg");
                this.openNavMobile = true;
            } else if (this.openNavMobile == true) {
                navList.classList.add("hide");
                document.body.classList.remove("overlay");
                navBtnImg.src = require("../assets/img/icons/nav-open.svg");
                this.openNavMobile = false;
                setTimeout(() => {
                    navList.classList.remove("open");
                    navList.classList.remove("hide");
                }, 400);
            }
        },
        openModal() {
            this.isModalVisible = true;
            document.body.classList.add("overlay");
        },
        closeModal() {
            const navList = document.querySelector(".nav-list");
            this.isModalVisible = false;
            if (navList.classList.contains("open") == false) {
                document.body.classList.remove("overlay");
            }
        }
    },
    mounted() {
        this.checkScroll();
    }
}
</script>
  
<style scoped>

.header {
    position: sticky;
    top: 0;
    padding: 8px 0;
    background-color: var(--color-bg);
    border-bottom: var(--border-line);
    z-index: 1;
}

.header-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    font-weight: 700;
    font-size: 35px;
    background: var(--background-gradient);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.nav-list {
    display: flex;
    column-gap: 50px;
    font-weight: 400;
    font-size: 18px;
}

.nav-list.open {
    display: flex;
}

.nav-button {
    display: none;
}

.nav-socials {
    display: none;
    column-gap: 50px;
    padding-top: 15px;
}

.nav-socials img {
    width: 40px;
    opacity: 0.7;
    transition: opacity .4s;
}

@media (hover: hover) {
    .nav-socials a:hover img {
        opacity: 1;
    }
}

@media (hover: none) {
    .nav-socials a:active img {
        opacity: 1;
    }
}

.nav-link {
    position: relative;
    color: var(--color-text);
    transition: color .25s ease-in;
    cursor: pointer;
}

.nav-link:hover {
    color: var(--color-text-3);
}

.nav-link::before {
    content: "";

    position: absolute;
    left: 0;
    top: 100%;

    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--color-text-3);
    transform: scale(0, 1);
    transition: transform .25s ease-in;
}

.nav-link:hover::before {
    transform: scale(1, 1);
}

/* Media queries */

@media (max-width: 1000px) {
    .nav-list {
        width: 100%;
        height: 100vh;
        padding: 30px 30px 100px 30px;
        overflow: auto;

        display: none;
        flex-direction: column;
        align-items: center;
        row-gap: 15px;

        position: fixed;
        top: 69.5px;
        left: 0;

        font-size: 28px;
        background-color: var(--color-bg);
        animation-name: animateOpenBackground;
        animation-duration: .5s;
    }

    @keyframes animateOpenBackground {
        from {opacity: 0;}
        to {opacity: 1;}
    }

    .hide {
        animation-name: animateCloseBackground;
        animation-duration: .5s;
    }

    @keyframes animateCloseBackground {
        from {opacity: 1;}
        to {opacity: 0;}
    }

    .nav-item {
        width: 270px;
        padding-bottom: 15px;
        text-align: center;
        border-bottom: var(--border-line);
    }

    .nav-link::before {
        display: none;
    }

    .nav-button {
        display: block;
    }

    .nav-socials {
        display: flex;
    }
}

@media (max-width: 580px) {
    .header {
        padding: 5px 0;
    }

    .logo {
        font-size: 28px;
    }

    .nav-list {
        padding: 30px 15px 85px 15px;
        top: 52.5px;
    }
}

@media (max-width: 374px) {
    .nav-list {
        padding: 25px 15px 78px 15px;
        row-gap: 10px;
        font-size: 21px;
    }

    .nav-item {
        width: 100%;
        padding-bottom: 10px;
    }

    .nav-socials {
        column-gap: 35px;
        padding-top: 10px;
    }

    .nav-socials img {
        width: 30px;
    }
}

</style>
