export const API_KEY = "314b2cbaf27e77089544354e3025e2ea";
export const BASE_URL = "https://api.openweathermap.org/data/2.5/weather";

export const CITIES = [
    {
        id: 1,
        city: "Miami",
        route: "/miami"
    },
    {
        id: 2,
        city: "Las Vegas",
        route: "/las-vegas"
    },
    {
        id: 3,
        city: "Monaco",
        route: "/monaco"
    }
];