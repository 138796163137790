import {createRouter, createWebHistory} from "vue-router";
import MainPage from "../pages/Main.vue";
import MiamiPage from "../pages/Miami.vue";
import LasVegasPage from "../pages/LasVegas.vue";
import MonacoPage from "../pages/Monaco.vue";

const routes = [
    {
        path: "/",
        component: MainPage
    },
    {
        path: "/miami",
        component: MiamiPage
    },
    {
        path: "/las-vegas",
        component: LasVegasPage
    },
    {
        path: "/monaco",
        component: MonacoPage
    }
];

const router = createRouter({
    routes, 
    history: createWebHistory()
});

export default router;