<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: "App"
}
</script>

<style>

/* Reset styles  */

@import "./assets/css/reset.css";

/* Fonts styles  */

@import "./assets/css/fonts.css";

/* Base style */

@import "./assets/css/base.css";

</style>