<template>
    <div class="modal" @click.stop="close" :class="{ 'hide-modal': isActiveHide }">
        <div class="modal-content" @click.stop :class="{ 'hide-modal-content': isActiveHide }">
            <img src="../assets/img/icons/nav-close.svg" @click="close" alt="Close" title="Close">
            <div class="modal-content-title">
                <p>This page is not ready yet.<br>We can offer you to view galleries of night cities:</p>
            </div>
            <div class="modal-content-btn">
                <a class="btn" href="/miami">Miami</a>
                <a class="btn" href="/las-vegas">Las Vegas</a>
                <a class="btn" href="/monaco">Monaco</a>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "ModalMain",
    data() {
        return {
            isActiveHide: false
        }
    },
    methods: {
        close() {
            this.isActiveHide = true;
            setTimeout(() => {
                this.$emit("close");
            }, 400);
        }
    }
}
</script>
  
<style scoped>

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.8);
    animation-name: animateOpenBackground;
    animation-duration: 0.5s;
}

@keyframes animateOpenBackground {
    from {background-color: rgba(0,0,0,0);}
    to {background-color: rgba(0,0,0,0.8);}
}

.hide-modal {
    animation-name: animateCloseBackground;
    animation-duration: 0.5s;
}

@keyframes animateCloseBackground {
    from {background-color: rgba(0,0,0,0.8);}
    to {background-color: rgba(0,0,0,0);}
}

.modal-content {
    width: 100%;
    max-width: 750px;
    height: max-content;
    max-height: 75vh;
    padding: 30px;
    margin: 0 30px;
    overflow: auto;
    border-radius: 10px;
    background-color: var(--color-bg-soft);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    animation-name: animateOpenModalZoom;
    animation-duration: 0.5s;
}

@keyframes animateOpenModalZoom {
    from {transform: scale(0)}
    to {transform: scale(1)}
}

.hide-modal-content {
    animation-name: animateCloseModalZoom;
    animation-duration: 0.5s;
}

@keyframes animateCloseModalZoom {
    from {transform: scale(1)}
    to {transform: scale(0)}
}

.modal-content img {
    margin-bottom: 8px;
    float: right;
    transition: transform .25s ease-in;
    cursor: pointer;
}

@media (hover: hover) {
    .modal-content img:hover {
        transform: scale(1.1);
    }
}

.modal-content-title {
    margin-bottom: 25px;
    font-size: 21px;
    font-weight: 400;
    line-height: 1.8;
    text-align: center;
    clear: both;
    cursor: default;
}

.modal-content-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 30px;
    row-gap: 15px;
}

/* Media queries */

@media (max-width: 580px) {
    .modal-content {
        margin: 0 15px;
    }

    .modal-content img {
        margin-bottom: 12px;
    }

    .modal-content-title {
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 2;
    }

    .modal-content-btn {
        column-gap: 15px;
    }

    .btn {
        font-size: 18px;
    }
}

@media (max-width: 374px) {
    .modal-content {
        padding: 20px 15px;
    }

    .modal-content-title {
        margin-bottom: 15px;
        font-size: 15px;
    }

    .btn {
        font-size: 15px;
    }
}

</style>
